import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';  // Import the custom CSS

const AdminLogin = ({ onLoginSuccess }) => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await axios.post('http://localhost:5000/admin/login', {
        name,
        username,
        password,
      });

      if (response.status === 200) {
        onLoginSuccess(); // Trigger login success
        alert('Login successful!');
        navigate('/add-product'); // Redirect to AddProduct page
      }
    } catch (error) {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="admin-login-page">
      <h2 className="admin-login-heading">Admin Login</h2>

      <div className="admin-login-form-container">
        <div className="admin-login-logo-container">
          <img src="/SRP Logo.png" alt="Logo" className="admin-login-logo" />
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleLogin} className="admin-login-form">
          <label htmlFor="name" className="admin-login-label">Name</label>
          <input
            type="text"
            id="name"
            placeholder="Rishabh Jha"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="admin-login-input"
          />

          <label htmlFor="username" className="admin-login-label">Username</label>
          <input
            type="text"
            id="username"
            placeholder="Your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="admin-login-input"
          />

          <label htmlFor="password" className="admin-login-label">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="admin-login-input"
          />

          <button type="submit" className="admin-login-button">Login</button>
        </form>

        <p className="admin-login-note">
          This form is only for admin login. Please{' '}
          <span 
            className="admin-login-redirect-home" 
            onClick={() => navigate('/')}
          >
            click here
          </span>{' '}
          to return back to the website.
        </p>
      </div>
    </div>
  );
};

export default AdminLogin;
