import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Slideshow.css';

const Slideshow = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimensions.width <= 768; // Adjust the breakpoint as needed

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
  };

  return (
    <div className="slideshow-container">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        showDots={true}
        customLeftArrow={<div className="custom-left-arrow">&#10094;</div>}
        customRightArrow={<div className="custom-right-arrow">&#10095;</div>}
      >
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 15.53.17.jpeg"
            alt="Slide 1"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 16.11.01.jpeg"
            alt="Slide 2"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 14.31.13.jpeg"
            alt="Slide 3"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 16.22.55.jpeg"
            alt="Slide 4"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 16.28.02.jpeg"
            alt="Slide 5"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 16.40.03.jpeg"
            alt="Slide 6"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
        
        <div>
          <img
            src="/WhatsApp Image 2024-10-02 at 16.36.07.jpeg"
            alt="Slide 7"
            className="slideshow-image"
            style={isMobile ? { width: '90%', height: 'auto' } : { width: '100%', height: 'auto' }} // Adjust for mobile
          />
        </div>
      </Carousel>
    </div>
  );
};

export default Slideshow;
