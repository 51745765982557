import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ onAdminLogin }) => {
  const [activeButton, setActiveButton] = useState('Home');
  const [dropdownActive, setDropdownActive] = useState(false); // State to handle dropdown visibility

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  return (
    <>
      {/* Top header with email and address */}
      <header className="top-header">
        <div className="contact-info">
          <div className="contact-item">
            <img src="/Email Logo.png" alt="Email Icon" className="icon" />
            <span>srprubberproducts@hotmail.com</span>
          </div>
          <div className="contact-item">
            <img src="/Maps Logo.png" alt="Address Icon" className="icon" />
            <a
              href="https://maps.app.goo.gl/9vqdKyeanpmjMzNu7?g_st=iw"
              target="_blank"
              rel="noopener noreferrer"
              className="address-link"
            >
              Vapi, Gujarat, India - 396155
            </a>
          </div>
        </div>
      </header>

      {/* Main header with logo and buttons (hidden in mobile view) */}
      <header className="main-header">
        <div className="logo">
          <img src="/SRP Logo.png" alt="Logo" />
        </div>

        <button className="hamburger" onClick={toggleDropdown}>
          &#9776; {/* Hamburger icon */}
        </button>

        <div className="call-us-now">
          <a href="tel:9924644666" className="call-button">
            <img src="/phone_logo.png" alt="Phone Icon" className="call-icon" />
            <span className="call-text">Enquire Now!</span>
          </a>
        </div>

        <nav className="nav-buttons">
          <Link to="/">
            <button
              className={activeButton === 'Home' ? 'selected' : ''}
              onClick={() => setActiveButton('Home')}
            >
              Home
            </button>
          </Link>
          <Link to="/about">
            <button
              className={activeButton === 'About Us' ? 'selected' : ''}
              onClick={() => setActiveButton('About Us')}
            >
              About Us
            </button>
          </Link>
          <Link to="/products">
            <button
              className={activeButton === 'Products' ? 'selected' : ''}
              onClick={() => setActiveButton('Products')}
            >
              Products
            </button>
          </Link>
          <Link to="/contact">
            <button
              className={activeButton === 'Contact Us' ? 'selected' : ''}
              onClick={() => setActiveButton('Contact Us')}
            >
              Contact Us
            </button>
          </Link>
        </nav>
      </header>

      {/* Dropdown Menu - Mobile */}
      <div className={`dropdown ${dropdownActive ? 'active' : ''}`}>
        <Link to="/">
          <button
            className={activeButton === 'Home' ? 'selected' : ''}
            onClick={() => {
              setActiveButton('Home');
              toggleDropdown(); // Close dropdown after selection
            }}
          >
            Home
          </button>
        </Link>
        <Link to="/about">
          <button
            className={activeButton === 'About Us' ? 'selected' : ''}
            onClick={() => {
              setActiveButton('About Us');
              toggleDropdown(); // Close dropdown after selection
            }}
          >
            About Us
          </button>
        </Link>
        <Link to="/products">
          <button
            className={activeButton === 'Products' ? 'selected' : ''}
            onClick={() => {
              setActiveButton('Products');
              toggleDropdown(); // Close dropdown after selection
            }}
          >
            Products
          </button>
        </Link>
        <Link to="/contact">
          <button
            className={activeButton === 'Contact Us' ? 'selected' : ''}
            onClick={() => {
              setActiveButton('Contact Us');
              toggleDropdown(); // Close dropdown after selection
            }}
          >
            Contact Us
          </button>
        </Link>
      </div>
    </>
  );
};

export default Header;
