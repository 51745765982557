import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    telephone: '',
    organization: '',
    message: '',
  });

  const [telephoneError, setTelephoneError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'telephone') {
      const telephonePattern = /^[0-9+-]*$/;
      if (!telephonePattern.test(value)) {
        setTelephoneError('Please enter a valid phone number using digits, +, or -');
        return;
      } else {
        setTelephoneError('');
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({
          name: '',
          email: '',
          address: '',
          telephone: '',
          organization: '',
          message: '',
        });
      } else {
        alert(`Failed to send message: ${data.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <div className="contact-us-page">
      {/* Overall header for both form and map */}
      <h1 className="contact-page-heading">Let's get in touch</h1>

      <div className="contact-content" style={{ display: 'flex', height: '100vh' }}>
        <div className="contact-form-container">
          <h2 className="contact-form-subheading">Feel free to ask your queries to us!</h2>

          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              placeholder="Your Name *"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address *"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address *"
              value={formData.address}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="telephone"
              placeholder="Telephone *"
              value={formData.telephone}
              onChange={handleChange}
              required
            />
            {telephoneError && <p className="error">{telephoneError}</p>}
            <input
              type="text"
              name="organization"
              placeholder="Your Organization *"
              value={formData.organization}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message (Optional)"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="google-map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.559169034696!2d72.85132906748875!3d20.294145416565122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0d3b32a6e57e1%3A0x98df35b81cbca28!2sSatindarpal%20Rubber%20Products%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1727682499409!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
