import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddProduct.css';

const AddProduct = ({ onAddProduct }) => {
    const [title, setTitle] = useState('');
    const [images, setImages] = useState(Array(4).fill(null)); // Four compulsory images
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleFileChange = (index, e) => {
        const newImages = [...images];
        newImages[index] = e.target.files[0];
        setImages(newImages);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', title);

        // Append images
        images.forEach((image, index) => {
            if (image || index === 0) { // Require first image, others are optional
                formData.append('images', image);
            }
        });

        try {
            const response = await axios.post('http://localhost:5000/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                setSuccessMessage("Product added successfully!");
                setErrorMessage('');
                resetForm(); // Reset the form after successful submission
            }
        } catch (error) {
            setErrorMessage("An error occurred while adding the product.");
            setSuccessMessage('');
        }
    };

    const resetForm = () => {
        setTitle('');
        setImages(Array(4).fill(null));
    };

    const handleViewEnquiry = () => {
        navigate('/admin-enquiry');
    };

    return (
        <div className="add-product-container">
            <h2>Add Product</h2>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                {/* Title input */}
                <div className="form-group">
                    <label>Title:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        placeholder="Enter product title" 
                        required 
                    />
                </div>

                {/* File inputs for images (one optional, four compulsory) */}
                {images.map((image, index) => (
                    <div className="form-group" key={index}>
                        <label>{`Image ${index + 1}:`}</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(index, e)} 
                            required={index === 0} // First image is required
                        />
                    </div>
                ))}

                {/* Submit button */}
                <button type="submit">Submit</button>
                <button type="button" onClick={handleViewEnquiry}>View Enquiry</button>
            </form>
        </div>
    );
};

export default AddProduct;